import React from "react"
import loadable from '@loadable/component'



import LogoCmsDrupal from './../../../../../images/custom/page/products/soft4cms/engines/drupal.png'
import LogoCmsGatsby from './../../../../../images/custom/page/products/soft4cms/engines/gatsbyjs-logo.svg'
import LogoCmsJoomla from './../../../../../images/custom/page/products/soft4cms/engines/Joomla.png'
// import LogoCmsDjango from './../../../../../images/custom/page/products/soft4cms/engines/django-logo-positive.svg'
import LogoCmsWordpress from './../../../../../images/custom/page/products/soft4cms/engines/WordPress-logotype-standard.png'

import LogoShopDrupalCommerce from './../../../../../images/custom/page/products/soft4shop/engines/drupal-commerce.svg'
import LogoShopMagento from './../../../../../images/custom/page/products/soft4shop/engines/magento.svg'
import LogoShopShopware from './../../../../../images/custom/page/products/soft4shop/engines/shopware.svg'
import LogoShopPrestashop from './../../../../../images/custom/page/products/soft4shop/engines/prestashop.svg'

import EditorGutenbergWpBlockGallery from './../../../../../components/s4n/Editor/Gutenberg/WpBlockGallery'



export default (node) => {
    const nid = typeof node.drupal_internal__nid !== `undefined` ? node.drupal_internal__nid : null;

    const ProductRelated = loadable(() => import(`./../../../../../components/s4n/Shop/Product/related`));
    const Subpages = loadable(() => import(`./../../../../../components/s4n/Menu/Subpages`));
    const Portfolio = loadable(() => import(`./../../../../../components/s4n/Portfolio`));
  
    let oReplacement = {
      [`[s4n_related_products]`]: <ProductRelated products={node.relationships && node.relationships.field_related_products ? node.relationships.field_related_products : []} />,
      [`[s4n_subpages]`]: <Subpages nid={nid} />,
      [`[s4n_subpages_service]`]: <Subpages nid={14} template={`service`} />,
      [`[s4n_portfolio]`]: <Portfolio nid={nid} header={true} limit={6} />,
      // [`dynamic-component-path=<Team propName="propValue" />`]: true,

      [`[s4n_products_soft4cms_images]`]: <React.Fragment>
        {/* <ImageS4n src={`custom/page/products/soft4shop/engines/drupal-commerce.svg`} alt={``} className="" _maxWidth="103px" /> */}
        {/* <ImageS4n src={`custom/page/products/soft4shop/engines/magento-logo.svg`} alt={``} className="" _maxWidth="103px" /> */}

        {(() => {
            // const LogoShopDrupalCommerce = loadable(() => import(`./../../images/custom/page/products/soft4shop/engines/drupal-commerce.svg`));
            // const LogoShopMagento = loadable(() => import(`./../../images/custom/page/products/soft4shop/engines/magento.svg`));
            // const LogoShopShopware = loadable(() => import(`./../../images/custom/page/products/soft4shop/engines/shopware.svg`));
            // const LogoShopPrestashop = loadable(() => import(`./../../images/custom/page/products/soft4shop/engines/prestashop.svg`));

            const items = [
                { className: `p-3`, alt: `Drupal`, src: LogoCmsDrupal, figcaption: null },
                { className: `p-3`, alt: `Wordpress`, src: LogoCmsWordpress, figcaption: null },
                { className: `p-3`, alt: `Joomla`, src: LogoCmsJoomla, figcaption: null },
                { className: `p-3`, alt: `Gatsby`, src: LogoCmsGatsby, figcaption: null },
            ]

            return (
                <div className="container">
                    <EditorGutenbergWpBlockGallery items={items} className="columns-4 box-white" />
                </div>
            )
        })()}
      </React.Fragment>,
      [`[s4n_products_soft4shop_images]`]: <React.Fragment>
        {/* <ImageS4n src={`custom/page/products/soft4shop/engines/drupal-commerce.svg`} alt={``} className="" _maxWidth="103px" /> */}
        {/* <ImageS4n src={`custom/page/products/soft4shop/engines/magento-logo.svg`} alt={``} className="" _maxWidth="103px" /> */}

        {(() => {
            // const LogoShopDrupalCommerce = loadable(() => import(`./../../images/custom/page/products/soft4shop/engines/drupal-commerce.svg`));
            // const LogoShopMagento = loadable(() => import(`./../../images/custom/page/products/soft4shop/engines/magento.svg`));
            // const LogoShopShopware = loadable(() => import(`./../../images/custom/page/products/soft4shop/engines/shopware.svg`));
            // const LogoShopPrestashop = loadable(() => import(`./../../images/custom/page/products/soft4shop/engines/prestashop.svg`));

            const items = [
                { className: `p-3`, alt: `DrupalCommerce`, src: LogoShopDrupalCommerce, figcaption: null },
                { className: `p-3`, alt: `Magento`, src: LogoShopMagento, figcaption: null },
                { className: `p-3`, alt: `Shopware`, src: LogoShopShopware, figcaption: null },
                { className: `p-3`, alt: `Prestashop`, src: LogoShopPrestashop, figcaption: null },
            ]

            return (
                <div className="container">
                    <EditorGutenbergWpBlockGallery items={items} className="columns-4 box-white" />
                </div>
            )
        })()}
    </React.Fragment>,
    }
  
    if (14 === nid) { // Service
      oReplacement[`[s4n_subpages]`] = <Subpages nid={nid} template={`service`} disableHeader={true} />
    }
    if (17 === nid) { // Portfolio
      oReplacement[`[s4n_portfolio]`] = <Portfolio nid={nid} />
    }

    return oReplacement;
}