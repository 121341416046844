import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import classNames from 'classnames';
// import Svg from './../../../Svg'

const ComponentStateless = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const items = props.items || null;

    if (null === items) {
        return null;
    }

    /**
     * Possible classes from gutenberg:
     * is-cropped
     * columns-4 ... 
     */
    const oClassNames = classNames({
        [`wp-block-gallery`]: true,
        [props.className]: props.className,
    });

    return (
        <React.Fragment>
            <figure className={oClassNames}>
                <ul className="blocks-gallery-grid">
                    {items.map(img => {
                        return (
                            <li className="blocks-gallery-item">
                                <figure>
                                    {/* <div class=" gatsby-image-wrapper"></div> === <ImageS4n src={`custom/page/products/soft4shop/engines/drupal-commerce.svg`} alt={``} className="" _maxWidth="103px" /> */}
                                    <img className={img.className} alt={img.alt} src={img.src} />
                                    {/* <Svg alt={img.alt} src={img.src} width="100%" /> */}
                                    {img.figcaption &&
                                        <figcaption className="blocks-gallery-item__caption">{img.figcaption}</figcaption>
                                    }
                                </figure>
                            </li>
                        )
                    })}
                </ul>
            </figure>
        </React.Fragment>
    )
}

export default ComponentStateless